<template>
  <div
    @click="select"
    class="neo-box-check"
    :class="{
      'neo-box-check--selected':
        value.id === selected || value.pm_id === selected,
    }"
  >
    <div class="neo-box-check__data">
      <slot />
    </div>
    <div class="neo-box-check__actions">
      <button type="button" @click="deleteAddress" title="Delete">
        <v-icon color="base">mdi-trash-can-outline</v-icon>
      </button>
      <button
        v-if="editable"
        type="button"
        title="Edit"
        @click="editAddress($event)"
      >
        <v-icon color="base">mdi-lead-pencil</v-icon>
      </button>
      <button type="button" title="Default Address" @click="defaultAddress">
        <v-icon
          :color="value.defaultAddress || value.default ? 'green' : 'base'"
          >mdi-star</v-icon
        >
      </button>
      <div v-if="value.defaultAddress" class="neo-box-check__default">
        <v-icon size="12" :color="value.defaultAddress ? 'white' : 'base'"
          >mdi-star</v-icon
        ><span>{{ defaultLabel }}</span>
      </div>
      <div v-if="value.default" class="neo-box-check__default">
        <v-icon size="12" :color="value.default ? 'white' : 'base'"
          >mdi-star</v-icon
        ><span>{{ defaultLabel }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NeoBoxCheck',
  props: {
    value: Object,
    selected: String,
    editable: Boolean,
    defaultLabel: String,
    mini: Boolean,
  },
  methods: {
    select() {
      this.$emit('select', this.value.id);
    },
    deleteAddress() {
      this.$emit('deleteAddress', this.value);
    },
    editAddress(event) {
      this.$emit('editAddress', this.value, event);
    },
    defaultAddress() {
      this.$emit('defaultAddress', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.neo-box-check {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
  border: 1px solid var(--color-grey);
  border-radius: 7px;
  padding: 8px;
  font-size: var(--font-size-extra-small);
  background-color: var(--color-white);
  &:only-child {
    cursor: default;
  }
  &--selected {
    border-color: green;
  }
  &__data {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 120px);
  }
  &__actions {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-end;
    > * {
      margin: 0 4px;
    }
    .v-icon:hover {
      color: var(--color-primary);
    }
  }
  &__default {
    position: absolute;
    bottom: -10px;
    right: 5px;
    background-color: var(--color-success);
    color: white;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;
    padding: 2px;
    font-size: var(--font-size-super-small);
  }
}
</style>
